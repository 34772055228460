<template>
  <button
    @click="handleNavigation(branch.type, branch.id)"
    class="flex flex-col items-center justify-center w-full h-full transition-transform duration-[0.3s] ease-[ease-in-out] hover:scale-105"
  >
    <hr class="w-full border-2 border-[#353437] mb-3" />
    <hr class="w-full border-2 border-[#353437]" />
    <div>
      <div
        class="border-x-4 border-[#353437] w-full px-20 h-10 max-sm:px-10"
      ></div>
    </div>
    <div
      class="flex flex-col w-full justify-center items-center bg-[#f7c104] gap-y-1 text-black py-3"
    >
      <div class="flex gap-x-2 max-sm:gap-x-2 text-center">
        <font-awesome-icon
          icon="fa-solid fa-plane-departure"
          class="h-10 w-10"
        />
        <h1 class="text-4xl">{{ branch.name }}</h1>
      </div>
      <h6
        class="font-light text-lg tracking-wider"
        :class="branch.speciality === ' ' ? 'h-7' : ''"
      >
        {{ branch.speciality }}
      </h6>
    </div>
    <img
      :src="branch.image"
      :alt="branch.name"
      class="h-[200px] w-full object-cover object-center"
    />
  </button>
</template>

<script>
import { useRouter } from "vue-router";

export default {
  name: "BranchCard",
  props: {
    branch: Object,
  },
  setup() {
    const router = useRouter();

    const handleNavigation = (type, id) => {
      if (type === "Restaurant") {
        if (id === 1) {
          router.push("/menu/gate-4");
        } else if (id === 2) {
          router.push("/menu/gate-5");
        } else if (id === 3) {
          router.push("/menu/gate-6");
        } else if (id === 4) {
          router.push("/menu/gate-7");
        }
      } else if (type === "Billiard") {
        router.push(`/menu/billiard`);
      } else if (type === "Karaoke") {
        if (id === 6) {
          router.push(`/menu/karaoke`);
        } else if (id === 7) {
          router.push(`/karaoke`);
        }
      }
    };

    return {
      handleNavigation,
    };
  },
};
</script>
